function menue_dropdown() {
  var togglerWrapper = $('.toggler-wrapper');

  if (togglerWrapper.length == 0) {
    return;
  }

  $('.bars-icon').on('click', function() {
    $(this).addClass('d-none');
    $('.close-icon').removeClass('d-none');
    $('.logo').addClass('d-none');
    $('.logo-white').removeClass('d-none');
    $('#navbar').addClass('expanded-nav');
    $('.fixed-div').removeClass('d-none');
    $('.menu-water-mark').removeClass('d-none');
  });

  $('.close-icon').on('click', function() {
    $(this).addClass('d-none');
    $('.bars-icon').removeClass('d-none');
    $('.logo').removeClass('d-none');
    $('.logo-white').addClass('d-none');
    $('#navbar').removeClass('expanded-nav');
    $('.fixed-div').addClass('d-none');
    $('.menu-water-mark').addClass('d-none');
  });
}

export { menue_dropdown };

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery-validation");
require("popper.js");
require("bootstrap");
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
window.$ = $;

require("trix")
require("@rails/actiontext")

require("@nathanvda/cocoon")

import { menue_dropdown } from "../components/menu_dropdown";
import { scroll_hander } from "../components/scroll";
import Sortable from 'sortablejs';

require('jssocials');
require('../lightbox');
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  customSelect()
  menue_dropdown();
  scroll_hander();

  $("form.contact-us").validate({
    ignore: "",
    errorPlacement: function(error, element) {
      element.parent().addClass('position-relative');
      error.insertAfter(element);
    }
  });

  $("#mc-embedded-subscribe-form").validate({
    ignore: "",
    errorPlacement: function(error, element) {
      element.parent().addClass('position-relative');
      error.insertAfter(element);
    }
  });

  if ($("#images").length > 0) {
    var el = document.getElementById('images');
    var sortable = Sortable.create(el, {
      draggable: '.nested-fields',
      onUpdate: updateImagesOrder
    });
  }
  
  const grid = document.querySelector('.grid');
  if (grid) {
    const msrn = new Masonry(grid, {
      itemSelector: '.grid-item',
      percentPosition: true,
      columnWidth: '.grid-sizer',
    });

    imagesLoaded(grid).on('progress', () => {
      msrn.layout();
    });
  }

  $('#images')
    .on('cocoon:after-insert', updateImagesOrder)
    .on("cocoon:after-remove", updateImagesOrder);

  $("#share-linkedin").jsSocials({
    shares: [{
      share: 'linkedin',
      logo: $("#share-linkedin").data('img')
    }],
    showLabel: false,
    showCount: false,
  });

  $("#share-facebook").jsSocials({
    shares: [{
      share: 'facebook',
      logo: $("#share-facebook").data('img')
    }],
    showLabel: false,
    showCount: false,
  });

  $("#share-twitter").jsSocials({
    shares: [{
      share: 'twitter',
      logo: $("#share-twitter").data('img')
    }],
    showLabel: false,
    showCount: false,
  });

  $("form").on("change", "input.file-input", function(){
    console.log(this.files)
    var image = this.files[0]

    if (image) {
      $(this).closest(".img-cover").find("img")[0].src = URL.createObjectURL(image);
    }
  })
})

function updateImagesOrder(){
  var images = $('#images .nested-fields');
  $.each(images, function(i, image){
    console.log(images.length - i);

    $(image).find('input[id$=_order]').val(images.length - i)
  })
}

function customSelect() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function(e) {
          /*when an item is clicked, update the original select box,
          and the selected item:*/
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
  }
}

function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

function scroll_hander() {
  $('.link_to_about_us, .scroll-down-btn').on('click', function() {
    $('.close-icon').trigger('click'); // Close menu then scroll

    var whoUsSection = $("#whoUsSection");

    if (whoUsSection.length == 0) {
      return document.location.href = "/#whoUsSection";;
    }

    $('html, body').animate({
      scrollTop: $("#whoUsSection").offset().top
    }, 1000);
  });

  $('.link_to_prev_project').on('click', function() {
    $('.close-icon').trigger('click'); // Close menu then scroll

    var previousProjectSection = $("#previousProjectSection");

    if (previousProjectSection.length == 0) {
      return document.location.href = "/#previousProjectSection";;
    }

    $('html, body').animate({
      scrollTop: $("#previousProjectSection").offset().top
    }, 1000);
  });

  $('.link_to_contact_us').on('click', function() {
    $('.close-icon').trigger('click'); // Close menu then scroll

    var contactUsSection = $("#contactUsSection");

    if (contactUsSection.length == 0) {
      return document.location.href = "/#contactUsSection";;
    }

    $('html, body').animate({
      scrollTop: $("#contactUsSection").offset().top
    }, 1000);
  });

  $('.scroll-down-to-brief').on('click', function() {
    var briefContainer = $('#briefContainer');

    if (briefContainer.length == 0) {
      return;
    }

    $('html, body').animate({
      scrollTop: $("#briefContainer").offset().top
    }, 500);
  });
}

export { scroll_hander };
